<template>
  <div class="col-lg-4 padCustom">
    <div class="sectionThreeBoxWrap">
      <div class="insideItemBoxWrap">
        <div
          v-if="loading"
          class="d-flex flex-column align-items-center"
          style="width: 100%"
        >
          <div
            class="d-flex flex-row flex-wrap justify-content-center"
            style="gap: 16px; width: 100%"
          >
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="100%"
              height="165px"
            />
          </div>
          <div
            class="d-flex flex-row flex-wrap justify-content-center"
            style="gap: 16px; width: 100%"
          >
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="100%"
              height="165px"
            />
          </div>
        </div>
        <draggable
          v-else
          v-model="dashboardSort.section_3"
          class="section-three row"
          group="table"
          :class="isDisable ? 'section-three-in-active' : ''"
          :style="{ flex: isSectionOne_Two && 1 }"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_3"
            :key="i"
            :loading="loading"
            :class="getClassName()"
          >
            <div :class="['smallSectionTwoBox', 'sortingDiv', getStyle()]">
              <div
                v-if="row.card_name == 'Cost Analysis'"
                class="barChart lineChartBasic"
              >
                <div class="sectionThreelargeItemBoxHeading">
                  <div class="dflexCustom">
                    Cost Analysis
                  </div>
                </div>
                <ApexLineChart
                  :options="options"
                  :data="dataValues"
                  :data-display="row.keys"
                  :currency="$t(`units.fuel_cost`) | currencyFormat(authUser)"
                  :width="'100%'"
                  :height="setHeigth(row.card_name)"
                />
              </div>

              <div
                v-else-if="row.card_name == 'Charge Sessions'"
                class="barChart"
              >
                <div class="sectionThreelargeItemBoxHeading">
                  <div class="dflexCustom">
                    Charge Sessions
                  </div>
                  <button
                    v-if="visibleButton"
                    class="reset-button chartFilterButton"
                    @click="resetToInitialStage"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </button>
                </div>
                <div class="apexBasicChart apexchargeSessionChart">
                  <ApexChargeSection
                    ref="ChargeChart"
                    :options="options"
                    :data="getDashboadChargeSessions.charge_sessions_chart_data"
                    :width="'100%'"
                    :height="setHeigth(row.card_name)"
                    :cursor-drill="cursorDrill"
                    @resetCursorValue="resetCursorValue"
                    @resetButton="resetButton"
                  />
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    // Icons: () => import("@/components/base/icons.vue"),
    ApexChargeSection: () =>
      import("@/components/sections/ApexChargeSection.vue"),
    ApexLineChart: () => import("@/components/sections/ApexLineChart.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      iconName: "",
      objObject: {},
      visibleButton: false,
      cursorDrill: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
      getDashboadChargeSessions: "dashboard/getDashboadChargeSessions",
      dataValues: "dashboard/getDashboadSectionThree",
    }),
    isSectionOne_Two() {
      return !this.dashboardSort?.section_1 && !this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  watch: {
    options: {
      handler: async function (v) {
        if (v) {
          this.visibleButton = false;
        }
      },
    },
  },
  mounted() {
    // Add an event listener to handle window resizing
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setHeigth(card) {
      let height = "220px"; // Default height

      if (this.dashboardSort.section_3.length === 1) {
        return (card === "Charge Sessions") ? "470px" : "500px";
      } else if (this.dashboardSort.section_3.length === 2) {
        if (this.windowWidth <= 1600) {
          return (card === "Charge Sessions") ? "180px" : "230px";
        } else if (this.windowWidth > 1600 && this.windowWidth <= 1900) {
          return (card === "Charge Sessions") ? "160px" : "230px";
        } else if (this.windowWidth > 1900) {
          return (card === "Charge Sessions") ? "150px" : "230px";
        }
      }

      return height;
    },
    resetButton() {
      this.visibleButton = true;
    },
    resetCursorValue(value) {
      this.cursorDrill = value;
    },
    resetToInitialStage() {
      this.visibleButton = false;
      let data = this.$store.getters["dashboard/getChargeSectionReset"];
      this.objObject = { ...this.options };
      this.objObject.date = [data[0].start, data[0].end];
      if (data.start == null && data.end == null) {
        delete this.objObject.dateType;
      }
      this.resetCursorValue(true);
      this.$store.dispatch("dashboard/setChargeBreadCrumb", []);
      this.$store.dispatch("dashboard/sectionChargeSession", this.objObject);
    },

    updateOrder() {
      const params = {
        section: "section_3",
        sort: true,
        item: this.dashboardSort.section_3,
        vehicle_tech: this.vehicleTech,
        tenant_id: this.options.tenant_id,
      };
      this.$store.dispatch("dashboard/updateCard", params);
    },

    getClassName() {
      if (
        this.dashboardSort.section_3 &&
        this.dashboardSort.section_3.length <= 2
      ) {
        return "col-lg-12";
      }
      return "col-lg-6";
    },
    getStyle() {
      return this.dashboardSort.section_3 &&
        this.dashboardSort.section_3.length == 1
        ? "minHeight"
        : "";
    },
    getLabel(val) {
      const labels = {
        electricity_cost: "Electricity Cost",
        fuel_cost: "Fuel Cost",
        savings: "Savings",
      };
      return labels[val] || "Unknown";
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinSemi: 'Poppins SemiBold', sans-serif

.largeItemsContentBoxes
  flex-direction: row-reverse

.section-two-in-active
  .v-card
    cursor: auto !important

.section-three
  width: 35%
  .v-card
    cursor: all-scroll
    background: transparent !important
.sectionThreelargeItemBoxHeading
  font-size: 1.25rem
  font-family: $poppinSemi
  margin-bottom: 0rem
  display: flex
.apexchargeSessionChart
  min-height: 220px
  max-height: 220px

.section-three .col-lg-12
    padding: 6px

.drawerOpen .section-three .smallSectionTwoBox
  min-height: 286px
  max-height: 286px

.drawerOpen .smallSectionTwoBox.minHeight
    min-height: 584px
    max-height: 584px

@media only screen and (max-width: 960px)
  .section-three
    width: 100%
</style>